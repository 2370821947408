import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
})
export class IndexComponent implements OnInit {
  countDownDate = new Date('July 29, 2022 19:00:00').getTime();
  leftString = '';

  constructor() { }

  ngOnInit(): void {
    this.setCountdown();  
  }

  setCountdown() {
    // Update the count down every 1 second
    let x = setInterval(() => {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = this.countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      this.leftString = `${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds until our reunion!`;

      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(x);
        this.leftString = '';

      }
    }, 1000);

    
  }
}
